.my-wishlist-container {
  position: fixed;
  top: 60px;
  right: 10px;
  border: 1px solid #d8d8d8;
  width: 90%;
  background: #f0eeee;
  z-index: 10;
  max-width: 95vw;
  padding: 20px 5px;
  max-height: 70vh;
  overflow: auto;
  max-width: 450px;
}

.wishlist-seperator{
  margin: 0px 20px 40px 20px;
}

.wishlist-category {
  font-size: 1.1rem;
  text-align: left;
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #0f0f0f;
  font-family: 'Cera Pro bold';
}


// .bg-red {
//   background: linear-gradient(
//     90deg,
//     rgba(246, 203, 161, 1) 0%,
//     rgba(255, 255, 255, 1) 28%,
//     rgba(255, 255, 255, 1) 72%,
//     rgba(246, 197, 161, 1) 100%
//   ) !important;
// }

// .bg-green {
//   background: linear-gradient(
//     90deg,
//     rgba(196, 246, 161, 1) 0%,
//     rgba(255, 255, 255, 1) 28%,
//     rgba(255, 255, 255, 1) 72%,
//     rgba(196, 246, 161, 1) 100%
//   ) !important;
// }

.wishlist-product-remove{
    position: absolute;
    z-index: 9;
    right: -18px;
    top: -18px;
    background: transparent;
    border: none;
    font-weight: 800;
    margin-right: 10px;
}

.qty-container {
  .qty-button {
    background: rgb(235, 233, 233);
    border: 2px solid #ffffff;
    margin-right: 5px;
    width: 100%;
    line-height: 47x !important;
    height: 37px;
    font-size: 15px;
    padding: 0px;
    font-weight: 800;
    margin-top: 5px;
    vertical-align: top;
  }

  .qty-value {
    width: 100%;
    text-align: center !important;
  }
}

.QtyHead{
  margin-top: 10px;
  //margin-right: 15px;
  //font-family: 'Cera Pro Bold';
}

.add-to-cart-block {
  text-align: center;
  .add-to-cart-button {
    background: #00003e;
    color: #fff;
    width: max-content;
    font-weight: 800;
    padding: 5px 15px;
    //border-radius: 25px;
    min-width: 100%;
    font-size: 1rem;
    border: 1px solid #242526;
    box-shadow: 0px 0px 4px #616161;
    margin-top: 10px;
  }
}

.pdp-slider-container{
  box-shadow: 0px 0px 3px;
  text-align: left !important;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
  .slick-track{
    margin-left: 1px;
  }
  .more-image-title{
    font-family: 'Cera Pro';
    font-size: 10px;
    margin-left: 5px;
  }
}

.product-container-wish {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 3px !important;
  vertical-align: text-top;

  .product-block {
    width: 97%;
    border: 0.5px solid #dddcdc;
    margin: auto;
    position: relative;
    background: #ffffff;

    .product-image-block {
      width: 100% !important;
      margin: auto;
      overflow: hidden;
      padding: 20px;
      border: 1px solid #eceaea;
    

      .product-image {
        //margin: 0 auto;
        width: 100% !important;
        height: auto;
        //object-fit: cover;
      }
    }

    .product-name {
      font-size: 0.9rem;
      font-family: 'Cera Pro';
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #131414;
      padding-top: 5px;
    }

    .product-description {
      font-size: 0.8rem;
      font-family: 'Cera Pro';
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #504e4e;
    }
  }
}

@media(min-width: 767px){
  .product-container-wish{
    max-width: 20% !important;
    margin-bottom: 20px !important;
    .product-block{
      width: 85%;
    }
  }
  .available-products{
    max-width: 25% !important;
    //margin-bottom: 20px !important;
  }

  .upcoming-products{
    max-width:20% !important;
    //margin-bottom:20px !important;
  }

  .wishlist-seperator{
    box-shadow: 0px 0px 5px;
    margin: 0px 34px 0px 30px ;
  }

  .wishlist-category {
    text-align: left;
    margin-left: 25px;
    padding-top: 5px;
    font-family: 'Cera Pro bold';
  }
  
  .continue-shopping-button {
    padding: 8px 20px !important;
    width:auto;
  }

  .product-block:hover {
    box-shadow: 0px 0px 9px #00003e;
  }
  
}