.header-container {
  width: 100%;
  background: #242662;
  height: auto;
  position: fixed;
  top: 0px;
  z-index: 22;
  background: linear-gradient(180deg, #01033F 0%, #2D2E7B 100.44%);
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

  .header-top-container {
    position: relative;
    width: 100%;
    height: 85px;
    margin-bottom: 10px;
  }

  .menu-icon {
    color: #ffffff;
    height: 30px;
    width: 25px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-2%, -50%);
    cursor: pointer;
  }

  .header-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      height: 45px;
    }
  }

  .store-message {
    position: absolute;
    top: 130%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    //font-family: Inter-SemiBold;
    font-family: 'Cera Pro Medium';
    font-size: 1rem;
  }

  .header-prints {
    color: white;
    //font-family: "Inter-SemiBold";
    font-family: 'Cera Pro Medium';
    font-size: 0.8rem;
  }

  .account-logo-container {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
    width: max-content;

    .account-logo {
      width: 28px;
      height: 28px;
      color: #ffffff;
    }
  }

  .bag-logo-container {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    vertical-align: middle;

    .bag-logo {
      width: 25px;
      height: 25px;
      color: #ffffff;
    }

    .cartCount {
      position: absolute;
      top: -13px;
      left: 15px;
      color: #ffffff;
      z-index: 9999;
      font-weight: 900;
    }
  }
}

.header-none {
  display: none !important;
}

.PrivateSwipeArea-root-1 {
  width: 0px !important;
}

.w-100 {
  width: 100% !important;

  svg {
    border-radius: 12px !important;
    padding: 1px;
  }
}

.bag-logo-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.drawer-container {
  width: 75vw;
  max-width: 280px;
  min-width: 250px;
  height: 100%;
  border: 1px solid #00003e;
  background: #faf9f7;
  background-repeat: no-repeat;

  .w-100:hover {
    background: #eaeded !important;
  }

  .drawer-logo-drawer {
    box-shadow: 0px 0px 2px;
    padding-left: 20px;
  }

  .logout-button {
    border: none;
    background: none;
    color: #00003e;
    font-weight: 800;
    font-size: 24px;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .drawer-welcome {
    margin-left: 5px;
    font-size: 0.9rem;
    font-family: 'Cera Pro Bold' !important;
    color: #242526;
    padding-left: 20px;
  }

  .drawer-trade {
    margin-left: 5px;
    font-size: 0.9rem;
    font-family: 'Cera Pro Black' !important;
    color: black;
    display: inline-block;
    padding-left: 20px;
  }

  .drawer-logo {
    width: 100%;
  }
}

.dialog-paper {
  min-width: max-content !important;
  max-width: max-content !important;
}

.header-drawer-link {
  margin-top: 8px;
  box-shadow: 0px 0px 2px;
  padding: 10px 20px;
  background: #ffffff;
  cursor: pointer;

  a {
    font-size: 1rem;
    font-family: 'Cera Pro Medium';
    color: #616161;

    span {
      vertical-align: middle;
    }
  }

  a:hover {
    //font-family: Inter-SemiBold;
    font-family: 'Cera Pro Medium';
  }

  span {
    font-size: 0.9rem;
    //font-family: Inter-SemiBold;
    font-family: 'Cera Pro Medium';
    color: #242526;
    vertical-align: middle;
  }

  span:hover {
    //font-family: Inter-SemiBold;
    font-family: 'Cera Pro Medium';
  }
}

.contact-us {
  margin-left: 5px;
  padding: 10px 0px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 800;
  color: hsl(240, 100%, 12%);
  border-top: 1px solid black;
}

.drawer-scrollable-container {
  height: calc(100vh - 27.6vw - 75px);
  overflow: auto;

  a {
    color: black;
    width: inherit;
    margin-bottom: 5px;
  }

  a:focus {
    text-decoration: none;
    color: black;
  }

  a:hover {
    text-decoration: none;
    color: black;
  }

  .email {
    font-size: 10px !important;
    vertical-align: baseline !important;
  }

  .last-container {
    margin-bottom: 10px;
  }
}

.header-logo-inner {
  position: relative;

  .trademark-logo {
    position: absolute;
    top: -5px;
    right: 20%;
    color: #ffffff;
    height: 12px;
    width: 12px;
  }
}

.copied-message {
  width: max-content;
  font-size: 14px;
  background: #d7d7d7;
  border-radius: 25px;
  border: 1px solid gray;
  padding: 5px 15px;
  margin: 10px auto;
}

.store-select {
  background-color: #ffffff;
  width: 100%;
  height: 30px;
  font-family: 'Cera Pro Bold';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  //background: #f1f1f1;
  border: 1px solid #cac5c5;
  padding: 0px 9px;
}

.store-radio {
  margin-top: 5px;
  margin-left: 15%;

  span {
    font-family: 'Cera Pro Medium';
    margin-right: 25px;
    margin-left: 0px;
  }

  svg {
    width: 20px;
  }

  .MuiRadio-root {
    padding: 0px;
    margin-right: 4px;
  }
}

.count-cirle {
  position: absolute;
  bottom: -5px;
  right: -5px;
  background: red;
  color: white;
  font-weight: 800;
  border-radius: 30px;
  width: 18px;
  text-align: center;
  font-size: 12px;
}

@media (min-width: 767px) {
  .drawer-scrollable-container {
    height: calc(100vh - 75px - 60px);
  }

  .menu-icon {
    left: 20px !important;
  }

  .drawer-logo {
    width: 100%;
    max-width: 200px !important;
  }

  .drawer-logo-drawer {
    padding:10px 0px 10px 0px;
  }
  
  .drawer-trade{
    padding-top:10px;
  }

  .drawer-welcome{
    padding-bottom:10px;
  }

}