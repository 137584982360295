.account-heading{
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 0px 8px;
    padding: 10px 20px;
    margin-top: 20px;

    svg{
        width: 35px;
        height: 35px;
        margin-right: 15px;
    }
}

.wishlist-account-heading{
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 0px 8px;
    padding: 10px 20px;
    margin-top: 20px;
    margin-left: 10px;

    svg{
        width: 35px;
        height: 35px;
        margin-right: 15px;
    }
}

.password-heading{
    font-size: 1.5rem;
    margin-top: 25px;
}

.my-account-fields{
    min-height: max-content !important;
}

@media(min-width: 767px){
    .account-heading{
        text-align: center;
    }
    .password-heading{
        margin-bottom: 25px ;
    }

    .account-partition{
        border-radius: 15px;
        box-shadow: 0px 0px 6px midnightblue;
        max-height: calc(100vh - 60px);
        overflow: auto;
    }
    .account-desktop-wrapper{
        min-height: calc(100vh - 10px) !important;
        max-height: calc(100vh - 10px) !important;
    }

    .account-desktop-banner-wrapper{
        min-height: calc(100vh - 60px) !important;
        width: calc(50vw - 1px);
        position: relative;
        max-height: calc(100vh - 60px) !important;
    }
    .banner-info-container{
        position: absolute;
        top: 32%;
        left: 10px;
        .banner-info-heading{
            font-size: 2.2rem;
            font-family: 'Cera Pro Bold';
        }
        .banner-info{
            font-size: 1.2rem;
            font-family: 'Cera Pro Medium';
        }
    }

    .password-container{
        .login-fields {
            min-height: 60vh !important;
        }
    }
}
@media(max-width: 767px){
    .account-partition{
        width: 100%;
    }
}