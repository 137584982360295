.welcome-page {
  width: 100vw;
  height: calc(100vh - 0px);
  position: relative;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .welcome-logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading {
  height: max-content;
}

.loader-image {
  position: absolute;
  width: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
