.logo-loader {
  animation: pulse 1s infinite;
  height: 400px;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
