.plp-container {
  position: relative;
  padding: 20px;

  .sort-by-title {
    padding: 10px 0px;
    margin: 0px 5px;
    font-family: 'Cera Pro Bold';
  }

  .sort-by-options {
    padding: 2px;
    margin: 0px;
    font-family: 'Cera Pro Medium';
    font-size: 0.6rem;
  }

  .filter-button {
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .w-30 {
    width: 100% ;
  
    svg {
      border-radius: 12px !important;
      padding: 1px;
    }
  }


  .filter-and-sort {
    margin-top: 20px;
    display:flex;
    justify-content: space-between;
  }


  .load-more-button-container {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    .load-more-button {
      background: transparent;
      border: 2px solid #00003e;
      font-size: 1rem;
      font-weight: 800;
      color: #00003e;
      padding: 8px 15px;
      border-radius: 25px;
    }
  }

  .cart-header {
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 2px 8px;
    // padding-left: 20px;
    padding-bottom: 20px;
  }

  .cart-place-order-single-item {
    // width: 100vw;
    // position: absolute;
    // bottom: 10px;
  }

  .cart-place-order {
    //padding: 10px 10px 20px 10px;
    padding: 10px 0px;
    //width: 97% !important;
    border-radius: 10px;
    border: 2px solid #f5f1f1;
    background: white;
    width: 100%;
  }

}

.plp-filter-count {
  bottom: 2px !important;
}

.no-products-message-outer {
  //padding: 50px;
  margin: auto;
}

.no-products-message {
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  color: black;
  padding: 5px;
  margin: auto;
  

  .no-prod-found {
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-top: 40px;
    text-align: center;
    color: #000000;
  }

  .no-products-sub-message {
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #111023;
    margin-top: 10px;
  }

  .need-help-message {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #111023;
  }
}

.empty-cart-message {
  font-family: 'Cera Pro Bold';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: auto;
  color: #111023;
  //margin-top: 10px;
}


.mycart-message {
  font-family: 'Cera Pro Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #131313;
  margin: 0px 10px 15px 0px;
}

.no-products-order-message {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: black;
  padding-top: 30px;
  padding-bottom: 10px;
  margin: auto;
}

.no-product-sub-message {

  font-weight: 500;
  font-size: 14px;
  color: #111023;
  line-height: 18px;
  // display: flex;
  // align-items: center;
  text-align: center;
}

.shopping-button-div {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.shopping-button {

  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  min-width: 200px !important;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}

.continue-shopping-button {

  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  //min-width: 400px !important;
  width: 90%;
  margin-top: 20px;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}

.product-list {
  margin-top: 15px;
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
  grid-gap: 20px;
}

.product-container {
  text-align: center;

  .product-block {
    margin: auto;
    position: relative;
    background: #ffffff;

    .ribbon {
      width: 60%;
      max-width: 150px;
      position: absolute;
    }

    .product-image-block {
      width: 100%;
      margin: auto;
      overflow: hidden;
      padding: 10px;
      background: #f1f1f1;
      border-radius: 10px;

      .product-image {
        width: 100%;
        border-radius: 10px;
      }
    }

    .product-name {
      font-family: 'Cera Pro Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #111023;
      margin-top: 10px;
    }

    .product-description {
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      max-width: 100%;
      color: #111023;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }

    .product-price {
      font-family: 'Cera Pro Bold';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      display: grid;
      grid-template-columns: 65% 30%;
      align-items: center;
      color: #111023;
      margin-top: 10px;
      text-align: left;
    }
  }
}

.filter-container {
  max-height: 70vh;
  //overflow-y: auto;
  min-height: 30vh;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;

  .filter-collapsible-container {
    overflow-y: auto;
  }

  .filter-close-botton {
    position: absolute;
    top: -2px;
    left: -5px;
    background: transparent;
    border: none;

    .filter-close {
      width: 15px;
      height: 15px;
      color: #242526;
    }
  }

  .filter-heading {
    text-align: center;
    color: black;
    position: relative;
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .filter {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #EEEEEE;
    position: relative;
  }

  .clear-all-button {
    position: absolute;
    top: 3px;
    right: 0px;
    font-family: 'Cera Pro Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .selected-container {
    margin-bottom: 10px;
  }

  .filter-collapsible {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    background: #ffffff;

    .filter-name {
      text-transform: capitalize;
      color: #242526;
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }

    .filter-arrow-container {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(0%, -50%);
      font-family: 'Cera Pro Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .background-grey {
    background: #f1f1f1;
  }

  .filter-data-block {
    display: inline-block;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    font-family: 'Cera Pro Medium';
    font-style: normal;
    font-weight: 500;
    padding: 6px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    text-transform: capitalize;

    .filter-checkbox {
      position: absolute;
      top: 50%;
      left: 2%;
      transform: translate(-2%, -50%);

      svg {
        background: #ffffff;
      }
    }
  }

  .filter-apply-button-container {
    position: fixed;
    //bottom: 0px;
    //left: 0px;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background: #faf9f7;
    z-index: 3;
    

    .cancel-button {
      width: 45%;
      border: 1px solid #242526;
      background: #ffffff;
      font-family: 'Cera Pro Bold';
      color: #242526;
      margin: 0px 2.5%;
      border-radius: 25px;
      padding: 8px 15px;
    }
  }
}

.pagination {
  text-align: center;
  display: block !important;
  padding-left: 10px !important;
  padding-right: 15px;
  width: max-content;
  margin: auto;
  margin-top: 30px;

  .previous {
    display: none !important;
  }

  .next {
    display: none !important;
  }

  li {
    display: inline-block;
    padding: 0px 7px;
    margin: 4px;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .active {
    background: #24266F;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    padding: 5px 10px;
  }

  .disabled {
    background-color: #acacac;
  }
}

.sorry {
  text-align: center;
  font-family: 'Cera Pro Bold';
  font-size: 1.2rem;
  color: orangered;
}

.no-prod-img {
  width: 100%;
  max-width: 550px;
  margin-top: 60px;
}

.no-order-prod-img {
  top: 282px;
  margin-top: 150px;
}




.pagination-container {
  margin-bottom: 45px;
  //margin-left: 340px;
}

.sort-div {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 6px;

  .sort-by-label {
    display: inline-block;
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-right: 10px;
  }

  .sort-by-dropdown {
    display: inline-block;

    .sort-by {
      font-family: 'Cera Pro Bold';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background: #f1f1f1;
      border: 1px solid #787777;
      padding: 0px 9px;

      .MuiButtonBase-root {
        font-family: 'Cera Pro Bold' !important;
      }
    }
  }
}

#demo-select-small {
  .MuiButtonBase-root {
    font-family: 'Cera Pro Bold' !important;
  }
}


.display-none {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.display-block {
  opacity: 1;
  display: block !important;
}

.plp-product-popup {
  .pdp-slider-container {
    box-shadow: none !important;
    text-align: center !important;
    margin-bottom: 8px;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
 
}

@media (min-width: 767px) {
  .plp-container {
   position: relative;

   .filter-and-sort {
    margin-top: 20px;
    display: block;
    
  }

   .cart-header {
    margin-left: 6px;
  }
    .cart-place-order {
      padding: 15px 45px 5px 45px;
      margin-bottom: 10px;
      margin-top: 5%;
      margin-left: 6px;
      width: 99% ;
  }

  .sort-div {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px
  }
    .sort-by-title {
      padding: 10px 5px;
      margin: 0px 18px;
      font-family: 'Cera Pro Medium';
    }

    .sort-by-options {
      padding: 0px;
      font-family: 'Cera Pro Medium';
      font-size: 0.6rem;
    }

    .desktop-filter-container {
      //max-height: calc(100vh - 50px);
      //height: calc(100vh - 55px);
      padding: 6px;
      padding-right: 30px;
      padding-left: 30px;
      min-width: 200px;

      .filter-data-block {
        display: block;
        width: max-content;
        height: 25px;

        .filter-data {
          font-size: 0.8rem;
          line-height: 15px;
          font-family: 'Cera Pro';
          padding-left: 15px;
        }
      }

      .filter-heading {
        font-size: 1rem;
        text-align: left;
        margin-bottom: 5px;
      }

      .filter-collapsible {
        border: none !important;
        background: transparent;
        padding: 0px !important;

        .filter-name {
          font-size: 0.8rem !important;
        }
      }

      .filter-collapsible-container {
        padding-bottom: 10px;
      }

      .filter-apply-button-container {
        background: transparent !important;
      }

      .cancel-button {
        width: max-content;
        margin-bottom: 5px;
        font-size: 0.8rem;
      }
    }

    .desktop-list-with-border {
      //max-height: calc(100vh - 65px);
      overflow: auto;
      border-left: 1px solid #616161;
      margin-bottom: 20px;
    }

    .desktop-list-no-border {
      //max-height: calc(100vh - 65px);
      margin:auto;
      overflow: auto;
      //border-left: 1px solid #616161;
      margin-bottom: 20px;
    }

    .need-help-banners{
      margin-left:550px; 
     }

    .filter-apply-button-container {
      max-width: 250px;
      position: absolute !important;
      left:15px;
      margin-top:30px;
    }

    .product-container {
      max-width: 85% !important;
      margin-left: 20px;

      .product-name {
      padding-left: 8px; 
      }

      .product-description {
        padding-left:8px;
      }
  
      .product-price{
        padding-left:8px;
        padding-bottom:5px;
      }

    }

    .product-block:hover {
      box-shadow: 0px 0px 9px #00003e;
    }

    .product-image {
      max-height: 20vw !important;
      height: auto;
    }

    .product-block {
      width: 75% !important;
      margin-bottom: 30px !important;
    }

    .add-to-cart-block {
      .add-to-cart-button {
        margin: 5px 10px !important;
      }
    }
  }

  .no-prod-img {
    max-width: 450px;
    margin-top: 60px;
  }

  .no-products-message {
    //margin-left:200px;
    border-left: 1px solid #616161 !important;
  }  

  .pagination-container {
    margin-left: 40px;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-30-banners {
    //width: 100% ;
  
    svg {
      border-radius: 12px !important;
      padding: 1px;
    }

    width: 30% !important;
    //margin-left: 400px;
  }

  .product-list {
    margin-top: 15px;
    display: grid;
    grid-template-columns: calc(25% - 5px) calc(25% - 5px) calc(25% - 5px) calc(25% - 5px);
    gap:10px;
    //border-left: 2px solid #616161;
    
  }

}




