.about-us-container {
  padding: 15px 5px;
}

.about-us-heading {
  font-weight: 800;
  font-size: 1.7rem;
  text-shadow: 1px 0px 4px #75aaf0;
  text-align: center;
}

.about-us {
  font-size: 0.9rem;
  text-align: justify;
  margin: auto;

  .about-us-content {
    margin: 15px 0px;
    letter-spacing: 1px;
    border-radius: 10px;
    padding: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: padding-box;


   
    .text-content {
      border-radius: 10px;
      padding: 10px;
      max-width: 600px;
      margin: auto;
      font-family: 'Cera Pro Medium';
      background: rgba($color: #ffffff, $alpha: 0.9);
    }

    .contact-text-content {
      border-radius: 10px;
      padding: 10px;
      max-width: 600px;
      margin: auto;
      font-family: 'Cera Pro Bold';
      font-size: 1.2rem;
      padding-bottom:40px;
      background: rgba($color: #ffffff, $alpha: 0.9);
      text-align:center;
    }

    .sub-text-content {
      padding-bottom: 20px;
    }

    .contact-icons {
      font-size: 1.2rem;
      padding-left: 10px;
      
    }

    .contact-text {
      padding-right:10px;
    }

    
  }

  .no-data-text {
    text-align: center;
    font-size: 1.2rem;
    padding: 80px 20px;
  }

  .focus-content {
    padding: 0px 20px;
    margin: 10px 0px;
  }

  .location-info {
    text-align: justify !important;
  }

  .single-line {
    margin: 6px 0px;
    word-break: break-all;
  }

  .about-us-icons {
    width: 2rem;
    height: 2rem;
    color: #242662;
  }

  .bg3 {
    background-image: url("../../assets/about-us/img3.jpg");
  }
}

.contact-us-border {
  border: 1px solid #d4d1d1;
  margin: 40px 20px 20px 20px !important;
  align-items: center;
  box-shadow: 1px 0px 9px #d4d1d1;
 
}

.italic {
  font-style: italic !important;
  color: gray !important;
}

.Border {
  width: 90%;
  margin: auto;
  max-width: 300px;
}

.border-container {
  text-align: center;
}


.contact-content{
  box-shadow: 1px 0px 9px #75aaf0;
  padding: 10px 0px;
  margin: 10px;
  border-radius: 10px;
}

.focus-content-container{
  position: relative;
  padding: 5px;
  margin: 20px 10px;

  .focus-content{
    padding: 0px;
    margin: 6px 10px;
    margin-bottom: 0px;
  }
}

.Border-top{
  width: 40px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.Border-down{
  width: 40px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

@media(min-width:767px){

  .focus-content-container{
    max-width: 600px;
    margin: 30px auto;
  }

  .about-us{
    .about-us-content{
      padding: 20px 80px 35px 80px;
      margin: 60px 120px 30px 120px !important;

      .text-content{
        margin-bottom: 20px !important;
      }

      .contact-icons {
        font-size: 1.2rem;
        padding-left: 10px;
        
      }
    }
  }

  .contact-content{
    max-width: 700px;
    margin: auto;
  }

}