.order-list {
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100vw;
  height: calc(100vh - 50px);
  position: relative;
  //background: #faf9f7;
  background: white;
  //border-top-left-radius: 20px;
 // border-top-right-radius: 20px;
  //box-shadow: 0px -4px 2px rgb(36, 36, 129);
  overflow: auto;
  overflow-x: hidden;

  .order-header {
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 2px 8px;
    padding-left: 20px;
    padding-top: 20px;

    svg{
        width: 35px;
        height: 35px;
        margin: 0px 13px 0px 2px;
    }
  }

  

  .order-measurement {
    font-size: 0.6rem;
    font-family: 'Cera Pro';
    color: #2e2828;
    display: inline-flex;
  }


  .order-block {
    margin: 20px 20px;
    padding: 10px;
    //box-shadow: 0px 1px 6px;
    border-radius: 10px;
    border: 2px solid #F1F1F1;
    background: white;
    width: 90%;

    .date {
      font-size: 0.9rem;
      font-family: 'Cera Pro';
      color: #616161;
    }

    .orderFulfill {
      color: rgb(25, 196, 25);
    }

    .orderReject {
      color: rgb(196, 5, 5);
    }

    .border-line {
      width: 100%;
      border: 1px solid #F1F1F1;
      //margin: 20px 0px;
    }
   }


   .order-block:hover {
    border: 1px solid rgb(46, 156, 199);
  }

  .order-info {
    //margin: auto;
    
    color: #242526;
    font-family: 'Cera Pro Bold';
    font-size: 1rem;
    text-align: left;
    padding-bottom:15px !important;
  }
  .order-view-details {
    margin: auto;
    
    font-family: 'Cera Pro';
    font-size: 1rem;
    text-align: center;
    padding: 10px !important;
  }

  .details-button {
    background: transparent;
    color: black;
    padding: 5px 15px;
    font-weight: 800;
    border: none;
    height: 100%;
  }
}

.link-contact-page {
  padding: 10px;
  text-align: center;
}

.contact-page {
  padding-left: 8px;
  color: blue;
}
.order-details-page {
  padding-top: 15px;
  //width: 90%;
  height: calc(100vh - 50px);
  min-height: calc(100vh - 50px) !important;
  position: relative;
  background: white;
  border: 3px;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  //box-shadow: 0px -4px 2px rgb(36, 36, 129);
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 15px;

  .order-outer-heading{
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 2px 8px;
    padding: 20px 20px 10px 20px;
  }

  .order-thankyou-heading{
    font-size: 1.5rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 2px 8px;
    padding: 20px 20px 5px 20px;
    text-align: center;
  }

  .order-status-outer-heading {
    font-size: 1.2rem;
    color: black;
    font-family: 'Cera Pro Bold';
    //box-shadow: 0px 2px 8px;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .order-heading {
    margin: 8px 8px;
    //width: 100vw;
    border: 2px solid #F1F1F1;
    padding: 10px 5px;
    background:#ffffff;
    font-size: 1.2rem;
    color: black;
    font-family: 'Cera Pro';
    border-radius: 4px;
    border-color:#F1F1F1;

    .order-id {
      font-size: 1.5rem;
      font-family: 'Cera Pro Bold';
    }
  }

  .order-heads {
    vertical-align: bottom;
    color: #000000;
    font-family: 'Cera Pro';
  }

  .status {
    text-transform: capitalize;
  }

  .order-img {
    width: 100%;
    height: 10rem;
    padding: 10px 0px;
  }

  .order-table-content {
    //border-bottom: 2px solid #d4d1d1;
    padding-top: 5px;
    padding-bottom: 20px;

    .order-label {
      font-family: 'Cera Pro';
      font-size: 0.8rem;
      min-width: 75px;
      display: inline-block;
    }

    .order-heads {
      font-family: 'Cera Pro';
      font-size: 0.9rem;
      text-align: left;
      padding-left: 15px;
      a {
        color: black;
        text-decoration: none;
      }
    }

    .order-measurement {
      font-size: 0.7rem;
      font-family: 'Cera Pro';
      color: #252222;
      display: inline-flex;
    }
    .order-item-details {
      padding: 10px 0px;
    }
  }

  .order-table-head {
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 10px;
    text-align: left;

    .order-heads {
      font-family: 'Cera Pro Bold';
      font-size: 1rem;
      text-align: left;
      padding-left: 15px;
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
  .order-logo-container {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-family: 'Cera Pro Bold';
  }

  .order-details-logo {
    width: 15px;
    max-width: 250px;
    margin-right: 5px;
  }

  .thank-you-message {
    margin: 5px 0px;
    margin-bottom: 0px;
    font-family: Allura;
    text-align: center;
    font-size: 3.5rem;
    color: #b52f1e;
    font-weight: 500;
    line-height: 3rem;
  }
  .thank-you-message-2 {
    text-align: center;
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-family: 'Cera Pro Bold';
    color: #4b714d;
    svg {
      width: 12px;
      margin: 7px;
    }
  }
  .thank-you-message-3 {
    margin: 5px 0px;
    margin-bottom: 0px;
    font-family: Denalova;
    text-align: center;
    font-size: 2rem;
    color: #c5b185;
    font-weight: 800;
    line-height: 2rem;
  }

  .details {
    margin: auto;
  }

  .thank-you {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
  }

  .order-heading-thankyou {
    margin: 8px 50px;
    //width: 100vw;
    border: 2px solid #F1F1F1;
    padding: 10px 5px;
    background:#ffffff;
    font-size: 0.9rem;
    color: black;
    font-family: 'Cera Pro';
    border-radius: 4px;
    border-color:#f7f6f6;
    text-align: center;

    .order-id {
      font-size: 1rem;
      font-family: 'Cera Pro Bold';
    }

  }

  .order-remarks {
    text-align: justify !important;
  }

  .remark-container {
    margin-top: 8px;
    background: white;
    width: 97%;
    margin: auto;
  }

  .check-icon-placement {
      text-align: center;
      font-size: 3rem;
      color: green;
      padding-top: 5px;
  }

  .order-placed-message {
    text-align: center;
    font-family: 'Cera Pro Medium';
    font-weight: 800;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 5px;
    color: #111023;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .thank-you-bottom-message {
    background: #cfcbcb;
    text-align: center;
    padding: 10px;
    margin: 20px 20px 40px 20px;
    font-size: 1rem;
    font-family: 'Cera Pro';
  }
}

@media (min-width: 767px) {
  .thankyou-page {
  }

  // .remark-container {
  //   width: 90%;
  //   margin: auto;
  // }

  .details {
    max-width: 400px;

    .order-heading {
      max-width: 400px;
      background: #faf9f7;
    }
  }

  .order-list {
    text-align: center;
    .order-block {
      padding: 10px;
      margin: 10px 20px;
      box-shadow: 0px 1px 3px;
      background: white;
      width: 65%;
      vertical-align: bottom;
      display: inline-flex;
    }
    .order-block:hover {
      box-shadow: 0px 1px 8px;
    }
  }

  .order-table-content {
    display: inline-flex !important;
    //width: 40% !important;
    max-width: 31% !important;
    justify-content: center;
    border-bottom: none !important;
    margin: 1%;
    margin-bottom: 2px;
    box-shadow: 0px 0px 5px #b4b4b4;
    background: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }

  .order-heading {
    box-shadow: none !important;
    padding: 10px 20px;
    margin-left:20px !important;
    margin-right:20px !important;
  }

  .order-item-details {
    // max-width: max-content !important;
  }

  .order-instructions-block {
    border: 1px solid #616161;
  }
}

