.homepage-container {
  padding-top: 3px;
  width: 100vw;
  position: relative;
  background: #ffffff;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 20px !important;
  position: relative;
  min-height: calc(100vh - 175px);

  .explore-message {
    font-family: 'Cera Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #131313;
    margin: 30px 30px 0px;
  }

  .block-container {
    .category-block-parent {
      text-align: center;
    }

    .category-block {
      padding: 10px;
      background: #f1f1f1;
      overflow: hidden;
      font-size: 1.2em;
      border-radius: 10px;
      min-height: 32%;
      text-align: center;
    }

    .block {
      text-align: center;
    }

    .cat-image {
      width: 100%;
      margin: auto;
      border-radius: 12px;
    }

    .cat-name {
      font-family: 'Cera Pro Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      margin-top: 10px;
      color: #131313;
    }

    .cat-description {
      font-size: 0.8rem;
      font-family: 'Cera Pro Medium';
      //white-space: nowrap;
      overflow: hidden;
      //text-overflow: ellipsis;
      color: #616161;
    }
  }

  .banner-container-single-category {
    width: 100vw;
    position: absolute;
    //bottom: 10px;
  }

  .banner-container {
    padding: 20px;
    margin-top: 45px;

    .banner-img {
      max-width: 100%;
      height: auto;
    }

    .slick-prev {
      left: 10px;
      z-index: 1;
    }

    .slick-next {
      right: 10px;
      z-index: 1;
    }

    .slick-arrow {
      color: #ffffff;
      width: 30px;
      position: absolute;
      z-index: 12;
      font-size: 24px;
    }

    .right {
      top: 50%;
      right: 0px;
      transform: translate(0%, -50%);
    }

    .left {
      top: 50%;
      left: 10px;
      transform: translate(0%, -50%);
    }

    .slick-active {
      button::before {
        content: url(../../assets/slickActive.png) !important;
      }
    }

    .slick-dots {
      button::before {
        content: url(../../assets/slickInActive.png);
      }
    }

  }

}

.category-block-page {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
  margin: 20px;
}

.category-block-page-single {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  margin: 20px;

}

.welcome-home {
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #FFFFFF;
  margin-left: 20px;
  margin-bottom: 10px;
}

.welcome-home-location {
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  position: absolute;
  bottom: -5px;
  left: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  svg {
    color: #ffffff;
    font-size: 16px;
    margin-right: 5px;
  }
}

@media (min-width: 767px) {
  .homepage-container {
    //height: calc(100vh - 50px) !important;
    min-height: calc(100vh - 50px) !important;

    .explore-message {
      margin-bottom: 30px;
    }

    .banner-container-single-category {
      width: 100vw;
      position: relative;
      bottom: 10px;
      //margin-top: 200px !important;
    }
  }

  .category-block:hover {
    box-shadow: 0px 0px 12px midnightblue !important;
    min-height: 32%;
  }

  .category-block-page-2 {
    text-align: left;
    margin: auto;
    width: 95%;
  }

  .category-block-page {
    text-align: center;
    width: 95%;
  }

 

    .category-block-page {
     // border-right: 1px solid #616161;
     // min-height: 50vh;
      display: grid;
      grid-template-columns: calc(20% - 100px) calc(20% - 100px) calc(20% - 100px) calc(20% - 100px) calc(20% - 100px) calc(20% - 100px);
      gap: 120px;
      margin: 30px;
      // text-align: center;
      // justify-content: space-evenly;
      // justify-items: center;
      // align-content: space-evenly;
      // align-items: center;
    }

    .category-block-page-single {
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
      margin: 20px;
    
    }
    
    .category-block-page-2 {
      margin: auto;
      width: 100%;
      border-right: 1px solid #616161;
      min-height: 100vh;
    }
  

  .banner-container {
    padding: 20px;
    margin-top: 200px !important;
    max-width: 100%;
    //height: 50vh;

    .banner-img {
      width: 100%;
      height: auto;
      max-height: 300px;
    }

  }

 
}



