.prod-img-block {
  display: inline-block;
  width: 35%;
  overflow: hidden;

  .prod-image {
    width: 80%;
    margin: auto;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 12px;
  }
}

.product-block-container {

  box-shadow: 0px 0px 3px #616161;
  padding: 10px 0px;
  position: relative;
  //background: white;
  background: #f1efef;
  border-radius: 5px;
  //width: 90% !important;
  margin: auto !important;
  margin-bottom: 20px !important;
}


.product-cart-item {
  width: 90%;
  margin: auto;
}

.remove-btn {
  border: 1px solid #575757 !important;
  border-radius: 12px !important;

  .closeIcon {
    color: #575757;
    margin-left: 10px;
    cursor: pointer;
    width: 1rem;
  }
}

.remove-button {
  background: #fa3333;
  // border: 1px solid #e7e8e8;
  font-family: 'Cera Pro';
  font-size: 0.9rem;
  border: 0px solid white;

  svg {
    font-size: 0.9rem;
    color: white;
  }
}

.QtyCounter {
  position: relative;
  display: inline-block;
  height: 37px;
  font-family: 'Cera Pro' !important;
  display: flex;
  align-items: center;
}

.cart-item {
  display: inline-block;
  width: 40px;
  text-align: center;
}

.cart-item-delete {
  display: inline-block;
  margin-left: 20px;
}

.QtyCounter .minus {
  width: 38px;
  height: 34px;
  display: inline-block;
  padding: 4px 10px;
  cursor: pointer;
  background-color: white;
}

.QtyCounter input {
  display: inline;
  width: 120px;
  height: 35px;
  font-family: 'Cera Pro Medium' !important;
  padding: 0px 40px;
  text-align: center;
  outline: none;
  overflow: hidden;
  color: #242526;
}

.QtyCounter .plus {
  right: 0;
  height: 34px;
  width: 38px;
  vertical-align: middle;
  padding: 4px 10px;
  cursor: pointer;
  background-color: white;
}

.QtyCounter .plus img {
  width: 13px;
}

.QtyCounter .minus img {
  width: 13px;
}

.button-disable img {
  opacity: 0.3;
}

.place-order-single-item {
  // width: 100vw;
  // position: absolute;
  // bottom: 10px;
}

.cart-place-order-button {

  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  //min-width: 400px !important;
  width: 98%;
  margin-top: 20px;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}

.place-order {
  padding: 10px 10px 20px 10px;
  //width: 97% !important;
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid #f5f1f1;
  background: white;
  margin: auto !important;

}

.order-button {
  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  min-width: 250px !important;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}

.remark-heading {
  font-size: 1rem;
  font-family: "Cera Pro Medium";
  color: #131313;
  margin-bottom: 5px;
}

.remarks-input {
  width: 100%;
  height: 100px;
  border: 1px solid #a19d9d;
  border-radius: 8px;
  margin-bottom: 20px;
}

.delivery-input {
  width: 100%;
  height: 60px;
  border: 1px solid #a19d9d;
  border-radius: 8px;
}


.remarks-input:focus {
  outline: none !important;
}

.place-order-button {
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 800 !important;
  text-transform: capitalize !important;
}

.not-disabled {
  background-color: #00003e !important;
}

.disabled {
  background-color: #b2b2b4 !important;
  pointer-events: "none"
}

.place-order-container {
  width: 100%;
  text-align: center;
}

.product-info {
  font-family: 'Cera Pro Medium';
  font-size: 0.8rem;
  //padding: 5px 0px;
  color: #131313;

  a {
    font-family: 'Cera Pro Medium';
    font-size: 0.8rem;
    padding: 5px 0px;
    color: #131313;
  }
}

.product-info-block {

  //  padding-top: 20px;
  .cart-qty-container {
    .qty-button {
      background: white;
      border: 2px solid #ffffff;
      //margin-right: 5px;
      width: 100%;
      //line-height: 47x !important;
      //height: 37px;
      font-size: 0.9rem;
      padding: 0px;
      font-weight: 800;
      //margin-top: 5px;
      //vertical-align: top;
    }

    .qty-value {
      background: #F1F1F1;
      border: 2px solid #F1F1F1;
      width: 100%;
      text-align: center !important;
    }
  }

  .available-qty {
    font-size: 12px;
    font-family: 'Cera Pro';
    padding-bottom: 35px;
  }
}

.product-upper-block {
  margin: 10px 5px;
  width: 97% !important;
}

.cart-product-name {
  font-size: 1rem !important;
  //margin-left: 12px !important;
  font-family: 'Cera Pro Bold';
  color: #242526;
}

.warning {
  color: red;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
}

.cart-page {
  position: relative;
  min-height: calc(100vh - 135px);
}

.fix-to-bottom {
  position:relative;
  bottom: 10px;
  margin-top:80px;
  //width: calc(100% - 40px) !important;
}

.start-shopping-button {
  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  min-width: 200px !important;
  cursor: pointer !important;
  margin-top: 30px;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}



@media(min-width: 767px) {
  .product-block-container {
    max-width: 32% !important;
    margin: 0.6% !important;
    background: url('../../assets/background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    .product-block:hover {
      box-shadow: none !important;
    }

  }

  .cart-place-order-button {
    padding: 8px 20px !important;
    width: auto;

}

  .place-order-container {
    margin-bottom: 15px;
}
  .cart-page {
   .product-block {
      margin-bottom: 0px !important;
      width: 90% !important;
    }
  }

  .prod-image {
    height: 10rem;
  }

  .place-order {
    box-shadow: none !important;
  }

  .product-block-container:hover {
    box-shadow: 0px 0px 8px #00003e;
  }

  .fix-to-bottom {
    position: relative;
    bottom: 10px;
    width: calc(100% - 40px) !important;
  }
}