html {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: 'Cera Pro Medium' !important;
}

.firebase-emulator-warning {
  display: none !important;
}


.dialog-message {
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
  padding: 20px;
  position: relative;
}


.dialog-message-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  padding: 0px;
  height: 1.5rem;
  border: none;
  font-size: 1.5rem;
  z-index: 12;
}

.app-container {
  margin-top: 100px;
  min-height: calc(100vh - 135px);
}

.app-container-home {
  margin-top: 140px;
  min-height: calc(100vh - 175px);
}

::-webkit-scrollbar {
  width: 6px;
  border: 1px solid black;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent !important
    /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #d7d7d7;
  /* creates padding around scroll thumb */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.popup-dialog-container {
  max-width: 400px;
  margin: auto;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.need-help-banners{
 margin-left:550px; 
}


.text-align-right {
  text-align: right !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.text-align-home-button {
  text-align: center !important;
}

.cera-pro-bold {
  font-family: 'Cera Pro Bold' !important;
}

.cera-pro {
  font-family: 'Cera Pro' !important;
}

.cera-pro-med {
  font-family: 'Cera Pro Medium' !important;
}

@media(min-width:767px) {
  .desktop-hidden {
    display: none;
  }

  .text-align-home-button {
    text-align: center !important;
  }
}

.w-100 {
  width: 100% !important;
}

.back-button {
  background: #24266F;
  border-radius: 4px;
  font-family: 'Cera Pro Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 175px;
  height: 50px;
  color: #FFFFFF;
}

.normal-button {
  background: #FFFFFF;
  border-radius: 4px;
  font-family: 'Cera Pro Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 175px;
  height: 50px;
  color: #24266F;
}