.login-container {
  width: calc(100vw);
  padding: 20px !important;
  position: relative;
  z-index: 10;
  background: #fff;

  .heading {
    text-align: center;
    margin: 15px 0px;
    font-size: 32px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 800;
    color: #00003e;
  }

  .login-fields {
    position: relative;
    min-height: calc(100vh - 220px);
    //min-height: calc(100vh - 320px);

    .login-field {
      margin: 0;
      width: 100%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .store-field {
      color: #1f1d1d !important;

      .MuiTypography-body1 {
        font-weight: 800 !important;
      }
    }

    .field {
      width: 100%;
      margin: 40px 0px;
      color: #00003e;

      .MuiRadio-colorSecondary.Mui-checked {
        color: #00003e !important;
      }

      .field-message {
        font-weight: 800;

        a {
          color: #00003e;
          text-decoration: underline;
        }
      }

      .field-label {
        margin-bottom: 5px;
        font-size: 18px;
        font-family: 'Cera Pro Medium';
        color: #242526 !important;
      }

      .carrier-code {
        width: 60px;
        height: 40px;
        border: 1px solid #1f1d1d;
        text-align: center;
        margin-right: 5px;
        box-shadow: 0px 12px 15px 0 rgba(0, 0, 0, 0.07);
        background: #fff !important;
        border-radius: 4px;
      }

      .mobile-number {
        width: calc(100% - 70px);
        height: 40px;
        border: 1px solid #1f1d1d;
        box-shadow: 0px 12px 15px 0 rgba(0, 0, 0, 0.07);
        padding: 0px 20px;
        border-radius: 4px;
        color: rgb(84, 84, 84);
      }

      .mobile-number:focus {
        box-shadow: 0px 0px 9px midnightblue;
      }

      .password {
        width: 100%;
        height: 40px;
        border: 1px solid #1f1d1d;
        box-shadow: 0px 12px 15px 0 rgba(0, 0, 0, 0.07);
        padding: 0px 20px;
        border-radius: 4px;
        color: rgb(84, 84, 84);
        letter-spacing: 0.05rem
      }

      .multi-select {
        margin: 0;
        background-color: #ffffff;
        outline: none;
        width: 100%;
        height: 20px;
        border-bottom: 1px solid black;
      }

      .password:focus {
        box-shadow: 0px 0px 9px midnightblue;
      }

      .password-absolute {
        position: absolute;
      }
      .password-container {
        position: relative;
        height: 40px;
      }

      .show-password {
        position: absolute;
        right: 5px;
        top: 6px;
      }

      .password-logo {
        height: 25px;
        width: 70px;
      }
      .password:focus {
        outline: none;
      }

      .mobile-number:focus {
        outline: none;
      }
    }
  }

  .login-button-div {
    text-align: center;

    .login-button:focus {
      // background: #151854;
      // color: #00003e;
      outline: none !important;
      box-shadow: 0px 12px 15px 0 rgba(0, 0, 0, 0.07);
    }
  }

  .register-button-div {
    margin-top: 20px;
  }
  .otp-button-div {
    margin-top: 15px !important;
    margin-bottom: 30px;
  }

  .register-message {
    text-align: center;
    margin-top: 10px;
    font-weight: 800;
    color: #00003e;

    a {
      color: #00003e;
      // text-decoration: underline;
      font-weight: 800;
    }
  }
}

.otp-input {
  width: 90% !important;
  height: 40px;
  border: 1px solid #1f1d1d;
  border-radius: 20px;
}

.login-button {
  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  min-width: 200px !important;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
}

.register-button {
  margin-top: 20px;
}

.profile-update-button {
  border: 1px solid #00003e !important;
  background: #00003e !important;
  padding: 8px 20px !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  min-width: 200px !important;
  cursor: pointer !important;
  //border-radius: 25px !important;
  color: #fff !important;
  outline: none !important;
  text-transform: none !important;
  margin-top: 20px;
  width: 100%;
}

.back-to-login {
  max-width: 100px;
  margin: auto;
}

.login-bg {
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.2;
  z-index: 3;
}

.error-field {
  font-size: 16px;
  color: red;
}

.field-message {
  text-align: center;

  a {
    color: #00003e;
  }
}

.logo-container {
  text-align: center;
  .logo {
    width: 150px;
  }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #00003e !important;
}

@media (min-width: 767px) {
  
  .login-container {
    min-height: 100vh !important;
    background-color: #f0efeb;
    padding: 0px !important;
    margin: 0px !important;
    .login-fields .login-field {
      margin: 0;
      width: 350px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .desktop-wrapper {
      width: 50vw;
      padding: 1px;
      display: inline-block;
      min-height: 99vh;
      max-height: 99vh;
      overflow: auto;
      // padding-left: 5vw;
      padding-top: 5vw;
      background: #faf9f7;

      // .logo-container {
      //   text-align: left;
      // }

      .login-fields {
        min-height: 40vh;
      }

      // .login-field{
      //   left: 0%;
      //   transform: translate(0%, -50%);
      // }

      // .login-button-div{
      //   text-align: left;
      // }

      // .register-message{
      //   text-align: left;
      // }
    }

    .desktop-banner-wrapper {
      width: 50vw;
      display: inline-block;
      min-height: 100vh;
      max-height: 100vh;
      overflow: hidden;
      vertical-align: bottom;
      position: relative;

      .desktop-banner {
        max-height: 100%;
        width: 100%;
        height: 100vh;
      }
    }
  }

  .profile-update-button {
    width: 0%;
  }
}
