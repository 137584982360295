.register-container{
    
    .field{
        margin: 20px 0px !important;
    }

    .password{
        width: 100% !important;
    }

    .password::before{
        border: none !important;
    }

    .MuiInput-underline:after{
        border: none !important;
    }
}

@media(min-width:767px){
    .register-container{
        overflow-y: hidden;
        .login-fields{
            width: 350px;
            margin: auto;
        }

        .desktop-wrapper{
            padding-top: 1vw;
        }
    }
}