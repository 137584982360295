@import "layout/common";
@import "Pages/login";
@import "Pages/register.scss";
@import "Pages/homepage.scss";
@import "layout/header.scss";
@import "layout/welcome.scss";
@import "Pages/plp.scss";
@import "Pages/cart.scss";
@import "Pages/pdp.scss";
@import "Pages/wishlist.scss";
@import "Pages/orders.scss";
@import "Pages/aboutus.scss";
@import "Pages/myAcoount.scss";

@font-face {
  font-family: "Inter-ExtraLight";
  src: url("../assets/fonts/Inter/static/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: "Inter-Light";
  src: url("../assets/fonts/Inter/static/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../assets/fonts/Inter/static/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../assets/fonts/Inter/static/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../assets/fonts/Inter/static/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/Inter/static/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../assets/fonts/Inter/static/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "Inter-Black";
  src: url("../assets/fonts/Inter/static/Inter-Black.ttf");
}


@font-face {
  font-family: "Denalova";
  src: url("../assets/fonts/denalova/denalova.ttf");
}


@font-face {
  font-family: "Allura";
  src: url("../assets/fonts/allura/Allura-Regular.ttf");
}

@font-face {
  font-family: "Cera Pro";
  src: url("../assets/fonts/CeraPro-Regular.woff");
}

@font-face {
  font-family: "Cera Pro Bold";
  src: url("../assets/fonts/CeraPro-Bold.woff");
}

@font-face {
  font-family: "Cera Pro Medium";
  src: url("../assets/fonts/CeraPro-Medium.woff");
}