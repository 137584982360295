.pdp-container {
  padding-top: 2rem;
  width: 100vw;
  height: calc(100vh - 50px);
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ffffff;
  overflow: auto;
  overflow-x: hidden;
  color: #a39f9f;
  margin-bottom:10px;

  .highlight {
    box-shadow: 0px 1px 12px #00003e;
    border: 1px solid #00003e;
  }
  .product-image-block {
    border-radius: 10px;
    overflow: hidden;
    padding: 8px;

    .product-image {
      width: 100%;
      margin: auto;
      border-radius: 10px;
    }
  }

  .product-name {
    font-size: 1.2rem;
    font-family: 'Cera Pro Bold';
    color: #242526;
    display: inline-block;
    margin-right: 5px;
  }

  .description-italic {
    font-style: italic !important;
  }

  .color-gray {
    color: #616161 !important;
    font-size: 0.8rem !important;
    font-family: 'Cera Pro';
    text-align: justify;
  }

  .product-designNumber-container {
    display: inline-block;
    color: #616161 !important;
    font-size: 0.8rem !important;
    font-family: 'Cera Pro';
  }

  .product-price-container {
    .product-price {
      font-size: 1.2rem;
      font-family: 'Cera Pro';
      color: #242526;
      display: inline-flex;
    }

    .product-measurement {
      font-size: 0.8rem;
      font-family: 'Cera Pro';
      color: #242526;
      display: inline-flex;
    }
  }

  .QtyHead {
    margin-top: 12px;
    margin-right: 15px;
    font-family: 'Cera Pro Bold';
    color: rgb(63, 60, 60);
  }

  .product-description-block {
    font-size: 1rem;
  }

  .add-to-cart-block {
    text-align: center;
    .add-to-cart-button {
      background: #00003e;
      color: #fff;
      width: max-content;
      font-weight: 800;
      padding: 8px 20px;
      //border-radius: 25px;
      min-width: 100%;
      font-size: 20px;
      border: 1px solid #00003e;
      //box-shadow: 0px 0px 4px #616161;
      margin-top: 30px;
    
    }
  }

  .notify-me-block {
    text-align: left !important;
  }

  .qty-container {
    .qty-button {
      background: rgb(240, 238, 238);
      border: 2px solid #ffffff;
      margin-right: 5px;
      width: 100%;
      line-height: 47x !important;
      height: 37px;
      font-size: 20px;
      padding: 0px;
      font-weight: 800;
      margin-top: 5px;
      vertical-align: top;
    }

    .qty-value {
      width: 100%;
      text-align: center !important;
    }
  }

  .out-of-stock {
    color: red;
    font-size: 1rem;
    margin-top: 5px;
    font-family: 'Cera Pro';
  }
  .upcoming {
    color: green;
    font-size: 1rem;
    margin-top: 5px;
    font-family: 'Cera Pro';
  }
}

.qty-heading {
  font-size: 1rem !important;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: left !important;
}

.slashed-price {
  text-decoration: line-through;
}
.hotdeals-price {
  color: red;
}

.text-align-right {
  text-align: right !important;
}

.border-bottom {
  border-bottom: 1px solid #00003e;
}

.border-top {
  border-top: 1px solid #00003e;
}

.product-zoom-image-block {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 3px;
  padding-bottom: 1px;
  position: relative;
}

.whatsapp-button {
  position: absolute;
  top: 5px;
  right: 5px;

  .share-icon {
    height: 50px;
  }
}

.pdp-slider-container {
  box-shadow: 0px 0px 3px;
  text-align: left !important;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
  .slick-track {
    margin-left: 1px;
  }
  .more-image-title {
    font-family: 'Cera Pro';
    font-size: 10px;
    margin-left: 5px;
    color: #a39f9f;
  }
}

.product-zoom-image {
  width: 100%;
  border-radius: 10px;
}

.transform-component-module_wrapper__1_Fgj {
  height: 100vh !important;
}

.product-dialog {
  padding: 0px !important;
  .MuiDialog-paper {
    margin: 0px !important;
    width: 100% !important;
    max-height: 100% !important;
    background: transparent;
    box-shadow: none;
  }
}

.available-qty {
  font-size: 12px;
  font-family: 'Cera Pro';

}

@media (min-width: 767px) {
  .pdp-slider-container {
    box-shadow: none !important;
    border-bottom: 1px solid #242526;

    .slick-list {
      height: 90vh !important;
    }
  }

  .product-zoom-image {
    height: 450px;
    width: 60% !important;
    margin-left: 75px;
  }
  

  .add-to-cart-button {
    margin-top: 2px !important;
  }
  .no-border {
    border-bottom: none !important;
  }
  .product-zoom-image-block {
    box-shadow: none !important;
    margin-left: 50px;
  }

  .root {
    background-color: "transparent";
  }

  .paper {
    background-color: "transparent";
    box-shadow: "none";
    overflow: "hidden";
  }

  .pdp-container{
    .product-image-block{

      .product-image {
       width: 75%;
       margin-left:20px;
      }
    }  
  }
  

}
